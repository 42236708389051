import { template as template_5fdaa0b6284844bcb38479770de5e517 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5fdaa0b6284844bcb38479770de5e517(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
