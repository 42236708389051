import { template as template_a1e5945471f84de4bd0fb8ecd751ce58 } from "@ember/template-compiler";
const FKLabel = template_a1e5945471f84de4bd0fb8ecd751ce58(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
