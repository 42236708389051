import { template as template_b17a4b78deb840009875efa8f5fd7f88 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b17a4b78deb840009875efa8f5fd7f88(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
