import { template as template_984b4a2e7f1c4f1f8fe5dc7e9d5137e3 } from "@ember/template-compiler";
const WelcomeHeader = template_984b4a2e7f1c4f1f8fe5dc7e9d5137e3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
